import { Injectable, Injector } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { LocalizationService } from "abp-ng2-module/dist/src/localization/localization.service";

@Injectable({
    providedIn: 'root'
})
export class AvaLocalizationService {
    localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
    localization: LocalizationService;

    constructor(
        private injector: Injector) {
        this.localization = injector.get(LocalizationService);
    }

    public l(key: string, ...args: any[]): string {
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    }

    private ls(sourcename: string, key: string, ...args: any[]): string {
        let localizedText = this.localization.localize(key, sourcename);

        if (!localizedText) {
            localizedText = key;
        }

        if (!args || !args.length) {
            return localizedText;
        }

        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    }
}