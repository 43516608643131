import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { Injector } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from '@shared/helpers/PrimengTableHelper';
import * as _ from 'lodash';
import {
    AvaCartItemDto,
    AvaPricingInfoDto,
    AvaProductAddOnDto, AvaProductConfigurationDto,
    AvaProductDetailDto,
    AvaProductDto,
    AvaPurchaseDecisionDtoOfAvaProductDto,
    IAvaDealDetailDto,
    IAvaPricingInfoDto,
    IAvaProductDto,
    IAvaPurchaseDecisionDtoOfAvaProductDto,
    PermissionServiceProxy
} from '../service-proxies/service-proxies';
import { ImpersonatorPermissionsService } from '../../app/shared/common/services/impersonator-permissions.service';
import * as moment from 'moment';
import { AppMarkets, ProductItemType } from '@shared/AppEnums';
import { AvaLocalizationService } from '@shared/services/ava-localization.service';

export abstract class AppComponentBase {
    localization: LocalizationService;
    avalocalization: AvaLocalizationService;
    permission: PermissionCheckerService;
    feature: FeatureCheckerService;
    notify: NotifyService;
    setting: SettingService;
    message: MessageService;
    multiTenancy: AbpMultiTenancyService;
    abpSession: AbpSessionService;
    appSession: AppSessionService;
    primengTableHelper: PrimengTableHelper;
    ui: AppUiCustomizationService;
    appUrlService: AppUrlService;
    permissionService: PermissionServiceProxy;
    impersonatorPermissionService: ImpersonatorPermissionsService;

    protected defaultDateFormat: string = 'YYYY-MM-DD';
    protected defaultDateTimeFormat: string = 'YYYY-MM-DD HH:mm:ss';
    
    isLoggedIn = abp.session.userId != null && abp.session.userId > 0;
    // Tenant
    isPartner = this.isLoggedIn && abp.session.tenantId > 0;
    isHost = this.isLoggedIn && !this.isPartner;

    constructor(injector: Injector) {
        this.impersonatorPermissionService = injector.get(ImpersonatorPermissionsService);
        this.localization = injector.get(LocalizationService);
        this.avalocalization = injector.get(AvaLocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.abpSession = injector.get(AbpSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
    }

    l(key: string, ...args: any[]): string {
        return this.avalocalization.l(key, ...args);
    }

    isGranted(permissionName: string, impersonationPermission?: string): boolean {
        var userPermission = this.permission.isGranted(permissionName);

        if (!userPermission || !impersonationPermission) return userPermission;

        return this.isGrantedForImpersonator(impersonationPermission);
    }

    isGrantedForImpersonator(permissionName: string): boolean {
        if (this.abpSession.impersonatorUserId == 0 || this.abpSession.impersonatorUserId == null) return true;

        return this.impersonatorPermissionService.isGranted(permissionName);
    }

    isGrantedAny(...permissions: string[]): boolean {
        if (!permissions) {
            return false;
        }

        for (const permission of permissions) {
            if (this.isGranted(permission)) {
                return true;
            }
        }

        return false;
    }

    s(key: string): string {
        return abp.setting.get(key);
    }

    appRootUrl(): string {
        return this.appUrlService.appRootUrl;
    }

    paginate(event, data: any[]): void {
        const pg = event.page || 0;
        const pgSize = event.rows || this.primengTableHelper.defaultRecordsCountPerPage;
        const offset = pg * pgSize;
        this.primengTableHelper.records = _(data)
            .slice(offset < 0 ? 0 : offset)
            .take(pgSize)
            .value();
    }

    customPaginate(event, data: any[]): any[] {
        const pg = event.page || 0;
        const pgSize = event.rows || this.primengTableHelper.defaultRecordsCountPerPage;
        const offset = pg * pgSize;
        return _(data)
            .slice(offset < 0 ? 0 : offset)
            .take(pgSize)
            .value();
    }

    getHelpDeskEmail(): string {
        return AppConsts.helpDeskEmail;
    }

    getHelpDeskEmailAuthSupport(): string {
        return AppConsts.helpDeskEmailAuthSupport;
    }

    getSupportEmailAddress(): string {
        return AppConsts.supportEmailAddress;
    }

    getPartnerCenterUrl(): string {
        return AppConsts.partnerNetworkUrl;
    }

    isEmptyObject(obj: any) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    }

    public isSpecialChar(c): boolean {
        return ((c > 64 && c < 91) || (c > 96 && c < 123) || c == 8 || c == 32 || (c >= 48 && c <= 57));
    }

    public isNewline(code): boolean {
        return ((code == 'Enter'));
    }

    public getDealTypeEnumName(dealType: number): string {
        switch (dealType) {
            case 1:
                return this.l("MarketplaceQuote");
            case 2:
                return this.l("CCWQuote");
            case 3:
                return this.l("CCWEstimate");
            case 4:
                return this.l("HPEIQouteBasket");
            case 5:
                return this.l("CCWRenewal");
            default:
                return this.l("MarketplaceQuote");
        }
    }

    public getLeadTimeLabel(leadTime: moment.Moment): string {
        let leadTimeLabel = moment.duration(moment().diff(leadTime)).humanize();
        if (leadTimeLabel === 'a few seconds') {
            return 'N/A';
        }

        return leadTimeLabel;
    }

    public mapDealToCartItem(deal: IAvaDealDetailDto, cartItemId: number, cartId: number): AvaCartItemDto {
        let priceExcluding = deal.totalExc;
        let priceIncluding = deal.totalInc;

        let product = new AvaProductDto({
            description: deal.description,
            name: deal.name,
            market: deal.market.valueOf(),
            productIdentifier: deal.dealIdentifier,
            brand: deal.brand.valueOf(),
            itemType: ProductItemType.Deal.valueOf(),
            dealType: deal.dealType.valueOf(),
            imageUrl: undefined,
            additionalInfo: deal.additionalInfo,
            brandInfo: undefined,
            category: undefined,
            availableToSell: undefined,
            estimatedTimeOfArrival: undefined,
            discount: undefined,
            discountPercentage: undefined,
            price: priceIncluding,
            currencyCode: undefined,
            promotions: undefined,
            tags: undefined,
            isDeal: undefined,
            vendorId: undefined,
        });

        let purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: product,
            tenantId: deal.tenantId,
            customerId: deal.customerId,
            purchaseOptionId: undefined,
            purchaseOptionDescription: undefined,
            autoRenew: undefined,
            quantity: 1,
            addOns: undefined,
            productConfigurationId: undefined,
            termUnitOfMeasure: undefined,
            parentOfferId: undefined,
            term: undefined,
            offerId: undefined,
            billing: undefined,
            billingFrequencyType: undefined
        });

        let pricingInfo = new AvaPricingInfoDto({
            discount: undefined,
            discountPercentage: undefined,
            unitPriceExc: priceExcluding,
            unitPriceInc: priceIncluding,
            sellPriceExc: priceExcluding,
            sellPriceInc: priceIncluding,
            quantity: 1,
            inStock: undefined,
            currencyCode: deal.sellCurrency,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice : undefined,
        } as IAvaPricingInfoDto);

        let cartItem = new AvaCartItemDto({
            purchaseDecision: purchaseDecision,
            pricingSummary: pricingInfo,
            splitPricing: undefined,
            cartId: cartId,
            cartItemId: cartItemId,
            tags: undefined,
            isValid: undefined,
            exception: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });
        
        return cartItem;
    }

    public mapAvaProductToCartItem(item: IAvaProductDto): AvaCartItemDto {
        let priceExcluding = Number(item.price);
        let priceIncluding = Number(item.additionalInfo['IncSellPrice']);

        let product = item;
        product.description = product.description ? product.description : product.name;
        product.itemType = ProductItemType.Product.valueOf();

        let purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: new AvaProductDto(product),
            tenantId: undefined,
            customerId: undefined,
            purchaseOptionId: undefined,
            purchaseOptionDescription: undefined,
            autoRenew: undefined,
            quantity: 1,
            addOns: undefined,
            productConfigurationId: undefined,            
            termUnitOfMeasure: undefined,
            parentOfferId: undefined,
            term: undefined,
            offerId: undefined,
            billing: undefined,
            billingFrequencyType: undefined
        });

        let pricingInfo = new AvaPricingInfoDto({
            discount: undefined,
            discountPercentage: undefined,
            unitPriceExc: priceExcluding / purchaseDecision.quantity,
            unitPriceInc: priceIncluding / purchaseDecision.quantity,
            sellPriceExc: priceExcluding ? priceExcluding : 0,
            sellPriceInc: priceIncluding ? priceIncluding : 0,
            quantity: purchaseDecision.quantity,
            inStock: item.availableToSell > 0,
            currencyCode: item.currencyCode,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice : undefined,
        } as IAvaPricingInfoDto);

        let cartItem = new AvaCartItemDto({
            purchaseDecision: purchaseDecision,
            pricingSummary: pricingInfo,
            splitPricing: undefined,
            cartId: undefined,
            cartItemId: undefined,
            tags: undefined,
            isValid: undefined,
            exception: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });

        return cartItem;
    }

    public mapAvaProductDetailToPurchaseDecision(
        productDetail: AvaProductDetailDto,
        isAutoRenew: boolean,
        purchaseQuantity: number,
        purchaseOptionsDisabled: boolean,
        purchaseOptionId: number,
        purchaseOptionDescription: string,
        pricedProductPrice: number,
        addons: AvaProductAddOnDto[],
        productConfiguration: AvaProductConfigurationDto,
        customerId?: number
    ): AvaPurchaseDecisionDtoOfAvaProductDto {

        let productConfigurationId = productConfiguration ? productConfiguration.configurationIdentifier : undefined;
        let productName = productConfiguration ? productConfiguration.configurationName : productDetail.name;
        
        let product = new AvaProductDto({
            imageUrl: productDetail.imageUrl,
            brandInfo: productDetail.brandInfo,
            category: productDetail.category,
            availableToSell: productDetail.availableToSell,
            estimatedTimeOfArrival: productDetail.estimatedTimeOfArrival,
            discount: productDetail.discount,
            dealType: productDetail.dealType ? productDetail.dealType.valueOf() : undefined,
            description: productDetail.description,
            name: productName,
            price: pricedProductPrice,
            currencyCode: productDetail.currencyCode,
            productIdentifier: productDetail.productIdentifier,
            promotions: productDetail.promotions,
            tags: productDetail.tags,
            brand: productDetail.brand.valueOf(),
            market: productDetail.market.valueOf(),
            itemType: productDetail.itemType.valueOf(),
            additionalInfo: productDetail.additionalInfo,
        } as IAvaProductDto);

        let purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: product,
            tenantId: undefined,
            customerId: customerId,
            purchaseOptionDescription: purchaseOptionsDisabled ? undefined : purchaseOptionDescription,
            purchaseOptionId: purchaseOptionsDisabled ? undefined : purchaseOptionId,
            autoRenew: isAutoRenew,
            quantity: purchaseQuantity,
            addOns: addons,
            productConfigurationId: productConfigurationId
        } as IAvaPurchaseDecisionDtoOfAvaProductDto);

        return purchaseDecision;
    }

    public mapAvaPurchaseDecisionToCartItem(
        purchaseDecision: IAvaPurchaseDecisionDtoOfAvaProductDto
    ): AvaCartItemDto {

        
        let priceInc: number = 0;
        let priceExc: number = 0;
        switch(purchaseDecision.product.market) {
            case AppMarkets.Microsoft:
            case AppMarkets.Adobe:{
                priceInc = AppConsts.addVAT(AppConsts.VATMultipler, purchaseDecision.product.price);
                priceExc = purchaseDecision.product.price;
                break;
            }
            default: {
                priceInc = Number.parseFloat(purchaseDecision.product.additionalInfo['IncSellPrice']);
                priceExc = purchaseDecision.product.price;
                break;
            }
        }        

        let pricingSummary = new AvaPricingInfoDto({
            unitPriceExc: priceExc,
            unitPriceInc: priceInc,
            quantity: purchaseDecision.quantity,
            discount: purchaseDecision.product.discount ? purchaseDecision.product.discount : 0,
            discountPercentage: purchaseDecision.product.discountPercentage ? purchaseDecision.product.discountPercentage : 0,            
            sellPriceExc: priceExc * purchaseDecision.quantity,
            sellPriceInc: priceInc * purchaseDecision.quantity,
            inStock: purchaseDecision.product.availableToSell > 0,
            currencyCode: purchaseDecision.product.currencyCode,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice : undefined,
        } as IAvaPricingInfoDto);

        let cartItem = new AvaCartItemDto({
            purchaseDecision: new AvaPurchaseDecisionDtoOfAvaProductDto(purchaseDecision),
            cartId: undefined,
            cartItemId: undefined,
            isValid: undefined,
            tags: undefined,
            exception: undefined,
            pricingSummary: pricingSummary,
            splitPricing: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });

        return cartItem;
    }

}
