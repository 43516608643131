import { Injector } from "@angular/core";
import { AppConsts } from "@shared/AppConsts";
import { LocalizationService } from "abp-ng2-module/dist/src/localization/localization.service";
import * as i0 from "@angular/core";
var AvaLocalizationService = /** @class */ (function () {
    function AvaLocalizationService(injector) {
        this.injector = injector;
        this.localizationSourceName = AppConsts.localization.defaultLocalizationSourceName;
        this.localization = injector.get(LocalizationService);
    }
    AvaLocalizationService.prototype.l = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        args.unshift(key);
        args.unshift(this.localizationSourceName);
        return this.ls.apply(this, args);
    };
    AvaLocalizationService.prototype.ls = function (sourcename, key) {
        var args = [];
        for (var _i = 2; _i < arguments.length; _i++) {
            args[_i - 2] = arguments[_i];
        }
        var localizedText = this.localization.localize(key, sourcename);
        if (!localizedText) {
            localizedText = key;
        }
        if (!args || !args.length) {
            return localizedText;
        }
        args.unshift(localizedText);
        return abp.utils.formatString.apply(this, args);
    };
    AvaLocalizationService.ngInjectableDef = i0.defineInjectable({ factory: function AvaLocalizationService_Factory() { return new AvaLocalizationService(i0.inject(i0.INJECTOR)); }, token: AvaLocalizationService, providedIn: "root" });
    return AvaLocalizationService;
}());
export { AvaLocalizationService };
