import {
    BannerImagesDtoUserViewRule,
    BannerImagesRequestAction,
    BusinessDocumentType,
    ChatMessageDtoReadState,
    ChatMessageDtoSide,
    CreatePaymentDtoEditionPaymentType,
    CreatePaymentDtoPaymentPeriodType,
    CreatePaymentDtoSubscriptionPaymentGatewayType,
    DefaultTimezoneScope,
    FriendDtoState,
    IncomeStatisticsDateInterval,
    IsTenantAvailableOutputState,
    LandingWidgetSettingDtoType, PriceAuditEntryDtoAuditKey,
    RegisterTenantInputSubscriptionStartType,
    SalesSummaryDatePeriod,
    ServiceDtoApplicationType,
    ServiceEntityType,
    TrackAreaClickDtoAction,
    TrackAreaClickDtoPage,
    UserNotificationState,
} from '@shared/service-proxies/service-proxies';
import { HubConnection } from '@aspnet/signalr';

export class AppChatMessageReadState {
    static Unread: number = ChatMessageDtoReadState._1;
    static Read: number = ChatMessageDtoReadState._2;
}

export class AppChatSide {
    static Sender: number = ChatMessageDtoSide._1;
    static Receiver: number = ChatMessageDtoSide._2;
}

export class AppFriendshipState {
    static Accepted: number = FriendDtoState._1;
    static Blocked: number = FriendDtoState._2;
}

export class AppTimezoneScope {
    static Application: number = DefaultTimezoneScope._1;
    static Tenant: number = DefaultTimezoneScope._2;
    static User: number = DefaultTimezoneScope._4;
}

export class AppUserNotificationState {
    static Unread: number = UserNotificationState._0;
    static Read: number = UserNotificationState._1;
}

export class AppTenantAvailabilityState {
    static Available: number = IsTenantAvailableOutputState._1;
    static InActive: number = IsTenantAvailableOutputState._2;
    static NotFound: number = IsTenantAvailableOutputState._3;
}

export class AppIncomeStatisticsDateInterval {
    static Daily: number = IncomeStatisticsDateInterval._1;
    static Weekly: number = IncomeStatisticsDateInterval._2;
    static Monthly: number = IncomeStatisticsDateInterval._3;
}

export class SubscriptionStartType {
    static Free: number = RegisterTenantInputSubscriptionStartType._1;
    static Trial: number = RegisterTenantInputSubscriptionStartType._2;
    static Paid: number = RegisterTenantInputSubscriptionStartType._3;
}

export class EditionPaymentType {
    static NewRegistration: number = CreatePaymentDtoEditionPaymentType._0;
    static BuyNow: number = CreatePaymentDtoEditionPaymentType._1;
    static Upgrade: number = CreatePaymentDtoEditionPaymentType._2;
    static Extend: number = CreatePaymentDtoEditionPaymentType._3;
}

export class ServiceApplicationType {
    static AdHoc: number = ServiceDtoApplicationType._1;
    static Custom: number = ServiceDtoApplicationType._2;
    static Default: number = ServiceDtoApplicationType._3;
    static All: number = ServiceDtoApplicationType._100;
}

export class ServiceCriteria {
    static StartsWith: number = 2;
    static Contains: number = 4;
    static EndWith: number = 3;
    static ExactMatch: number = 1;
}

export class EntityType {
    static Cisco_Estimate: number = ServiceEntityType._1;
    static Cisco_Product: number = ServiceEntityType._2;
}

export class AppEditionExpireAction {
    static DeactiveTenant = 'DeactiveTenant';
    static AssignToAnotherEdition = 'AssignToAnotherEdition';
}

export class PaymentPeriodType {
    static Monthly: number = CreatePaymentDtoPaymentPeriodType._30;
    static Annual: number = CreatePaymentDtoPaymentPeriodType._365;
}

export class SubscriptionPaymentGatewayType {
    static Paypal: number = CreatePaymentDtoSubscriptionPaymentGatewayType._1;
}

export class AppSalesSummaryDatePeriod {
    static Daily: number = SalesSummaryDatePeriod._1;
    static Weekly: number = SalesSummaryDatePeriod._2;
    static Monthly: number = SalesSummaryDatePeriod._3;
}

export class AppMarkets {
    static Default = 0;
    static IBM = 1;
    static Microsoft = 2;
    static IBM_MOCK = 3;
    static Cisco = 4;
    static Redhat = 5;
    static CiscoMock = 6;
    static MicrosoftMock = 7;
    static Huawei = 13;
    static GeneralMarket = 14;
    static HPE = 15;
    static Adobe = 16;
    static MicrosoftDesc = 'Microsoft';
    static IBMDesc = 'IBM';
    static CiscoDesc = 'Cisco';
    static RedhatDesc = 'Redhat';
    static CiscoMockDesc = 'CiscoMock';
    static MicrosoftMockDesc = 'MicrosoftMock';
    static DellDesc = 'Dell';
    static GeneralMarketDesc = 'Marketplace';
    static AdobeMarketDesc = 'Adobe';
}

export enum PaymentTypes {
    NoneSelected = 0,
    AxizCredit = 1,
    CapitecCredit = 2,
    AxizCDC = 3,
    OrderRequest = 4,
    AxizCloudCredit = 5,
    ProofOfPayment = 6
}

export class ConfigurableBrands {
    static HPE = 'HPE';
}

export enum AppMarketsDesc {
    IBM = 1,
    Microsoft = 2,
    Cisco = 4,
    Redhat = 5,
    CiscoMock = 6,
    MicrosoftMock = 7,
    Marketplace = 14,
    Adobe = 16
}

export enum AuthorizationType {
    Basic,
    OAuth2,
    Custom,
    None,
    HpeValidation
}

export enum PriceRuleGrouping {
    Governance= 1,
    Product = 2,
    Partner = 3,
    Special = 4
}

export enum PricingBreakdownKey {
    ROE = 1,
    Monthly = 2,
    MonthlyInclusive = 3,
    Annual = 4,
    AnnualInclusive = 5,
    VAT = 6,
    VATCost = 7,
    SellPriceInclusive = 8,
    UnitListPrice = 9,
    Discount = 10,
    Qty = 11,
    ExtendedNetPrice = 12,
    LogisticsCost = 13,
    Margin = 14,
    BuyPrice = 15,
    Uplift = 16,
    SellPrice = 17,
    DealQty = 18
}

export class OAuth2Providers {
    static CCW = 1;
    static CCWDesc = 'Cisco Commerce Workspace';
}

export class PricingCalculationType {
    static AddOnPricing = 'AddOnPricing';
    static AzureUsageDto = 'AzureUsageDto';
    static CiscoQuoteLineDto = 'CiscoQuoteLineDto';
}

export class TenantStatuses {
    static Active = 1;
    static InActive = 2;
    static Suspended = 3;
    static RegistrationInProgress = 4;
}

export class AddressFormMode {
    static View = 'view';
    static CreateOrEdit = 'create-or-edit';
}

export class AddressFormLayout {
    static Edit = 'edit';
    static Create = 'create';
}

export class CustomerFormLayout {
    static Edit = 'edit';
    static Create = 'create';
}

export class MicrosoftSubscriptionUpdateType {
    static SeatCount = 'seatCount';
    static SubStatus = 'subStatus';
    static BillingFreq = 'billingFrequency';
}

export class TagActions {
    static None = 1;
    static Add = 2;
    static Remove = 3;
}

export class ServiceActions {
    static None = 1;
    static Add = 2;
    static Remove = 3;
}

export class MicrosoftPartnerRelationship {
    static None = 1;
    static Reseller = 2;
    static Customer = 3;
}

export class ServiceStatusEnum {
    static Active = true;
    static Inactive = false;
}

export enum DocumentType {
    Notification = 1,
    Warning = 2
}

export class ServicesPricingMethodType {
    static CustomPrice = 1;
    static FixedPrice = 2;
    static Margin = 3;
    static Markup = 4;
    static Quantity = 5;
}

export class ServicePropertyMappingType {
    static SingleMapping = 'Single';
    static MultipleMapping = 'Multiple';
}

export class OrderDeliveryMethod {
    static DeliverOrder = 'Delivery';
    static CollectOrder = 'Collect';
}

export class OrderStatuses {
    static Requested = 1;
    static Ordered = 2;
    static BackOrder = 3;
    static InTransit = 4;
    static Delivered = 5;
    static 'Partially Delivered' = 6;
    static Cancelled = 7;
    static 'Request Error' = 8;
}

export enum SelectableVariableType {
    //Add Enums Here
    '%' = 1,
    ZAR = 2,
    USD = 3,
    EUR = 4
}

export class FixedPriceType {
    static Margin = 'Margin';
    static IPA = 'IPA';
    static QuantityReference = 'Quantity Reservation';
    static Volume = 'Volume';
}

export enum PaymentTransactionStatus {
    NotDone = 0,
    Approved = 1,
    Declined = 2,
    Cancelled = 3,
    UserCancelled = 4,
    Received = 5,
    Voided = 7,
    ChecksumUnverified = 1001,
    TransactionUnverified = 1002
}

export enum PaygateMode {
    Production = 1,
    Local = 2,
    BypassPayGate = 3
}

export enum MicrosoftProductType {
    OnlineServicesNCE = 'OnlineServicesNCE'
}

export enum DealTypes {
    /// <summary>
    /// The ava deal. Created by a salesperson within the AVA platform
    /// </summary>
    MarketplaceQuote = 1,

    /// <summary>
    /// We get this from Cisco API from CCW Quote endpoint
    /// </summary>
    CCWQuote = 2,

    /// <summary>
    /// We get this from Cisco API from CCW Estimate endpoint
    /// A deal.
    /// </summary>
    CCWEstimate = 3,

    /// <summary>
    /// HPE Iquote external cart
    /// The subscription. Typically Microsoft
    /// </summary>
    HPEIQouteBasket = 4,

    /// <summary>
    /// We get this from Cisco API from CCW Renewal endpoint
    /// A deal.
    /// </summary>
    CCWRenewal = 5,

    MicrosoftQuote =  7
}

export enum BrandEnum {
    // Make sure to update ERP brands in Micro-services when this file is updated. The two files should match
    Lenovo = 1,
    Logitech = 2,
    HPE = 3,
    Dell = 4,
    DellE = 5,
    HPIP = 6,
    HPIC = 7,
    Audiocodes = 8,
    Adobe = 9,
    AOC = 10,
    Axiz = 11,
    Cisco = 12,
    Citrix = 13,
    Connex = 14,
    Corel = 15,
    IBM = 16,
    ICCN = 17,
    Innova = 18,
    Ixia = 19,
    Kingston = 20,
    Legion = 21,
    Magix = 22,
    Mcafee = 23,
    MicroFocus = 24,
    Micropoint = 25,
    Microsoft = 26,
    Mindjet = 27,
    NComputing = 28,
    Nettrace = 29,
    Norton = 30,
    NozomiNetworks = 31,
    Nutanix = 32,
    Nuance = 33,
    RedHat = 34,
    Ring = 35,
    Solar = 37,
    Supermicro = 38,
    Suse = 39,
    Targus = 40,
    Veeam = 41,
    Veritas = 42,
    VMWare = 43,
    Volkano = 44,
    WesternDigital = 45,
    Zimbra = 46,
    Rocket = 47,
    TechnologyServices = 48,
    CloudTechnologies = 49,
    DataCentrix = 50,
    Forcepoint = 51,
    GeneralMarket = 52,
    Services = 53,
    Unbranded = 300,
    Capitec = 54,
    Cradlepoint = 55,
    Imeon = 56,
    Trina = 57,
    SolarEdge = 58,
    SchneiderElectric = 59,
}

export enum RegisterBusinessDocumentType {
    EntityRegistration = BusinessDocumentType._0,
    ProofOfVatRegistration = BusinessDocumentType._1,
}


export enum ProductItemType {
    Product = 1,
    Deal = 2,
    Subscription = 3,
}

export enum DealAdditionalInfo {
    QuoteId = 1,
    DealId = 2,
    EndUser = 3,
    ParentId = 4,
    ParentProductId = 5,
    Sku = 6,
    PartNumber = 7,
    DealReference = 8,
    BasketId = 9,
    OrderName = 10,
    SmartAccountDomain = 11,
    DealEta = 12,
    Index = 13,
    DealName = 14,
    DocumentDate = 15,
    ExpiryDate = 16,
    IsAutoQuote = 17,
    IsActive = 18,
    ExportMessage = 19,
    QuoteName = 20,
    CreatedBy = 21,
    CreatedDate = 22,
    InstanceId = 23,
    ServiceLevel = 24,
    ServiceLevelDescription = 25,
    TargetContractNumber = 26,
    PAKSerialNumber = 27,
    ReferenceSerial = 28,
    EndDate = 29,
    StartDate = 30,
    InstanceSerial = 31,
    CreatorEmail = 32,
    Supplier = 33,
    OpgRef = 34,
    OpgQuantity = 35,
    OpgEndDate = 36,
    URLProductImage = 37,
    URLProductSpecs = 38,
    SystemId = 39,
    Class = 40,
    VendorListPrice = 41,
    VendorListPriceCurrencyCode = 42,
    Discount = 43,
    PriceProtectionDate = 44,
}

export enum LandingWidgetType {
    SINGLE = LandingWidgetSettingDtoType._0,
    FULL = LandingWidgetSettingDtoType._1,
    FOUR = LandingWidgetSettingDtoType._2
}

export enum BannerEditAction {
    UNCHANGED = BannerImagesRequestAction._0,
    ADD = BannerImagesRequestAction._1,
    REMOVE = BannerImagesRequestAction._2,
    UPDATE = BannerImagesRequestAction._3,
}

export enum TrackingClickAction {
    VIEW = TrackAreaClickDtoAction._0,
    ADD_TO_CART = TrackAreaClickDtoAction._1,
    REGISTER = TrackAreaClickDtoAction._2,
}

export enum TrackingClickPage {
    LANDING_PAGE = TrackAreaClickDtoPage._0,
    CART = TrackAreaClickDtoPage._1,
}

export enum CountryCodes {
    SOUTH_AFRICA = 207,
}

export enum UserViewRule {
    ALL = BannerImagesDtoUserViewRule._0,
    SIGNED_IN = BannerImagesDtoUserViewRule._1,
    SIGNED_OUT = BannerImagesDtoUserViewRule._2,
}

export enum AcceptanceTerms {
    SELF_REGISTRATION = 0,
    ADOBE_NEW_RESELLER = 1,
}

export enum OnboardingRequestType {
    TOC,
    MODAL
}

export enum CartCheckoutDisable {
    ADOBE_CUSTOMER_NOT_VERIFIED,
    MICROSOFT_CUSTOMER_NOT_VERIFIED,
    PAYMENT_METHODS_NOT_VALID,
    PROOF_OF_PAYMENT,
    MAX_PAYMENT_EXCEEDED,
    TENANT_NOT_FOUND,
    DOC_UPLOAD_REQUIRED,
    PENDING_PAYMENT,
    AVA_REFERENCE_REQUIRED,
    MISSING_PERMISSION,
    REQUIRE_PURCHASE_ORDER_NUMBER
}

export enum CustomerMenuBarAction {
    NONE,
    EXPORT_MICROSOFT,
    EXPORT_ADOBE,
    SAVE
}

export enum SubscriptionBillingPeriods {
    unknown = 0,
    yearly = 1,
    monthly = 2,
}

export enum SubscriptionCommitmentPeriods {
    unknown = 0,
    annual = 1,
    monthToMonth = 2,
    _3Years = 3,
    onceOff = 4,
}


export enum ConfirmationModalTypes {
    Activate=1,
    Deactivate=2,
    LegacyAutoRenewal=3,
    CancelSubscription = 4,
}

export enum PricingFileStatus {
    Created = 1,
    ProcessingConfiguration = 2,
    ReadyForConfiguration = 3,
    ProcessingImportRules = 4,
    Complete = 5,
    Aborted = 6,
    Error = 7,
}

export enum PricingVariableEnum {
    BRAND = 27,
    SKU = 26,
    FIXED_PRICE = 22,
    IPA = 20,
    QUANTITY = 19,
    REBATE = 4,
    MARGIN = 13,
    VOLUME = 28,
}

export enum PricingAuditEnum {
    ListPrice = PriceAuditEntryDtoAuditKey._1,
    VendorDiscount = PriceAuditEntryDtoAuditKey._2,
    RoE = PriceAuditEntryDtoAuditKey._3,
    EstimatedFreight = PriceAuditEntryDtoAuditKey._4,
    FreightMechanism = PriceAuditEntryDtoAuditKey._5,
    OverrideFreight = PriceAuditEntryDtoAuditKey._6,
    Duties = PriceAuditEntryDtoAuditKey._7,
    SABS = PriceAuditEntryDtoAuditKey._8,
    LandingPrice = PriceAuditEntryDtoAuditKey._9,
    VendorMarketingUplift = PriceAuditEntryDtoAuditKey._10,
    ResellerUplift = PriceAuditEntryDtoAuditKey._11,
    AxizUplift = PriceAuditEntryDtoAuditKey._12,
    CorporateUplift = PriceAuditEntryDtoAuditKey._13,
    InsuranceUplift = PriceAuditEntryDtoAuditKey._14,
    FinanceUplift = PriceAuditEntryDtoAuditKey._15,
    AverageCost = PriceAuditEntryDtoAuditKey._16,
    ReplacementCost = PriceAuditEntryDtoAuditKey._17,
    Margin = PriceAuditEntryDtoAuditKey._18,
    AxizSellPrice = PriceAuditEntryDtoAuditKey._19,
    ResellerMargin = PriceAuditEntryDtoAuditKey._20,
    ResellerSellPrice = PriceAuditEntryDtoAuditKey._21,
    VAT = PriceAuditEntryDtoAuditKey._22,
    VATInclusiveSellPrice = PriceAuditEntryDtoAuditKey._23,
    VendorDiscountedSellPrice = PriceAuditEntryDtoAuditKey._24,
    DeferredRebatePrice = PriceAuditEntryDtoAuditKey._25,
    RebateDiscount = PriceAuditEntryDtoAuditKey._26,
    RebateClaim = PriceAuditEntryDtoAuditKey._27,
    BuyPrice = PriceAuditEntryDtoAuditKey._28,
    RebateAppliedListPrice = PriceAuditEntryDtoAuditKey._29,
    RebateAppliedLandingPrice = PriceAuditEntryDtoAuditKey._30,
    RebateAppliedAverageCostPrice = PriceAuditEntryDtoAuditKey._31,
    RebateAppliedEstimatePrice = PriceAuditEntryDtoAuditKey._32,
    RebateAppliedSellPrice = PriceAuditEntryDtoAuditKey._33,
    VendorDiscountPercentage = PriceAuditEntryDtoAuditKey._34,
    MarginPercentage = PriceAuditEntryDtoAuditKey._35,
    EstimatedFreightPercentage = PriceAuditEntryDtoAuditKey._36,
    VendorMarketingUpliftPercentage = PriceAuditEntryDtoAuditKey._37,
    ResellerUpliftPercentage = PriceAuditEntryDtoAuditKey._38,
    AxizUpliftPercentage = PriceAuditEntryDtoAuditKey._39,
    CorporateUpliftPercentage = PriceAuditEntryDtoAuditKey._40,
    InsuranceUpliftPercentage = PriceAuditEntryDtoAuditKey._41,
    FinanceUpliftPercentage = PriceAuditEntryDtoAuditKey._42,
    AvailableToSell = PriceAuditEntryDtoAuditKey._43,
    CostPriceCurrency = PriceAuditEntryDtoAuditKey._44,
    ProductDiscount = PriceAuditEntryDtoAuditKey._45,
    ProductDiscountPercentage = PriceAuditEntryDtoAuditKey._46,
    TacticalUplift = PriceAuditEntryDtoAuditKey._47,
    ManualVendorMarketingUplift = PriceAuditEntryDtoAuditKey._48,
    TacticalUpliftPercentage = PriceAuditEntryDtoAuditKey._49,
    ManualVendorMarketingUpliftPercentage = PriceAuditEntryDtoAuditKey._50,
    Freight = PriceAuditEntryDtoAuditKey._51,
    VendorMarketingUpliftMechanism = PriceAuditEntryDtoAuditKey._52,
    ResellerUpliftMechanism = PriceAuditEntryDtoAuditKey._53,
    AxizUpliftMechanism = PriceAuditEntryDtoAuditKey._54,
    CorporateUpliftMechanism = PriceAuditEntryDtoAuditKey._55,
    InsuranceUpliftMechanism = PriceAuditEntryDtoAuditKey._56,
    FinanceUpliftMechanism = PriceAuditEntryDtoAuditKey._57,
    TacticalUpliftMechanism = PriceAuditEntryDtoAuditKey._58,
    ManualVendorMarketingUpliftMechanism = PriceAuditEntryDtoAuditKey._59,
    DutiesMechanism = PriceAuditEntryDtoAuditKey._60,
    SABSMechanism = PriceAuditEntryDtoAuditKey._61,
    MarginMechanism = PriceAuditEntryDtoAuditKey._62,
    ResellerMarginMechanism = PriceAuditEntryDtoAuditKey._63,
    UsedLot = PriceAuditEntryDtoAuditKey._64,
    SupplierId = PriceAuditEntryDtoAuditKey._65,
    RebateReference = PriceAuditEntryDtoAuditKey._66,
    RebateType = PriceAuditEntryDtoAuditKey._67,
    VendorRebateClaim_DiscountAmount = PriceAuditEntryDtoAuditKey._68,
    VendorRebateClaim_DiscountCurrency = PriceAuditEntryDtoAuditKey._69,
    FromLot = PriceAuditEntryDtoAuditKey._70,
    IPAAmount = PriceAuditEntryDtoAuditKey._71,
    IPAPosting = PriceAuditEntryDtoAuditKey._72,
    RoEConvertedListPrice = PriceAuditEntryDtoAuditKey._73,
    RoEConvertedBuyPrice = PriceAuditEntryDtoAuditKey._74,
    LastPurchasePrice = PriceAuditEntryDtoAuditKey._75,
    TrueCostPrice = PriceAuditEntryDtoAuditKey._76,
    OriginalListPrice = PriceAuditEntryDtoAuditKey._77,
    OriginalLandingPrice = PriceAuditEntryDtoAuditKey._78,
    OriginalEstimateCostPrice = PriceAuditEntryDtoAuditKey._79,
    OriginalSellPrice = PriceAuditEntryDtoAuditKey._80,
    OriginalAverageCost = PriceAuditEntryDtoAuditKey._81,
    ResellerOriginalSellPrice = PriceAuditEntryDtoAuditKey._82,
    FreightFinanceMarginUplift = PriceAuditEntryDtoAuditKey._83,
    FreightUpliftValue = PriceAuditEntryDtoAuditKey._84,
    DutiesMechanismValue = PriceAuditEntryDtoAuditKey._85,
    IPAMechanism = PriceAuditEntryDtoAuditKey._86,
    FixedSellPriceMin = PriceAuditEntryDtoAuditKey._87,
    FixedSellPriceMax = PriceAuditEntryDtoAuditKey._88,
    FixedPrice = PriceAuditEntryDtoAuditKey._89,
    FixedPriceMargin = PriceAuditEntryDtoAuditKey._90,
    FixedSellPriceFluctuatingVariable = PriceAuditEntryDtoAuditKey._91,
    FromLotQuantity = PriceAuditEntryDtoAuditKey._92,
    AdditionalFee = PriceAuditEntryDtoAuditKey._93,
    AdditionalFeeMechanism = PriceAuditEntryDtoAuditKey._94,
    ProductDiscountMechanism = PriceAuditEntryDtoAuditKey._95,
    QuantityFulfillable = PriceAuditEntryDtoAuditKey._96,
    ReservationReference = PriceAuditEntryDtoAuditKey._97,
    ReservationQuantity = PriceAuditEntryDtoAuditKey._98,
    RebatePriceRuleId = PriceAuditEntryDtoAuditKey._99,
    RebatePriceRuleName = PriceAuditEntryDtoAuditKey._100,
    DealBasketId = PriceAuditEntryDtoAuditKey._101,
    LastPurchasePriceSource = PriceAuditEntryDtoAuditKey._102,
    CostPriceSource = PriceAuditEntryDtoAuditKey._103,
    PriceListSource = PriceAuditEntryDtoAuditKey._104,
    PriceListStartValidityDate = PriceAuditEntryDtoAuditKey._105,
    PriceListEndValidityDate = PriceAuditEntryDtoAuditKey._106,
    PriceListName = PriceAuditEntryDtoAuditKey._107,
    PromotionDiscount = PriceAuditEntryDtoAuditKey._108,
    PromotionDiscountPercentage = PriceAuditEntryDtoAuditKey._109,
    PromotionDiscountMechanism = PriceAuditEntryDtoAuditKey._110,
    AllUpliftSum = PriceAuditEntryDtoAuditKey._111,
}

export enum RebateMappingDecision {    
    FileRebateReference = 1,        
    GlobalRebateReference = 2,
}

export enum BillingCycle {
    Unknown = 0,
    Monthly = 1,
    Annual = 2,
    None = 3,
    OneTime = 4,
}

export enum LandingWidgetSection {
    EDIT,
    SEARCH_BAR,
    TOP_BANNER,
    MAIL_LIST,
    RECOMMENDED_PRODUCTS,
    WIDGETS,
    TOP_PRODUCTS,
    ONBOARDING
}

export enum LocationType {
    // This is the location that is shared by multiple tenants, will be on Axiz own server's.
    AxizServerShared = 1,
    AxizServerPrivate = 2,
    CustomServer = 3
}