import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { FeatureCheckerService } from '@abp/features/feature-checker.service';
import { LocalizationService } from '@abp/localization/localization.service';
import { MessageService } from '@abp/message/message.service';
import { AbpMultiTenancyService } from '@abp/multi-tenancy/abp-multi-tenancy.service';
import { NotifyService } from '@abp/notify/notify.service';
import { SettingService } from '@abp/settings/setting.service';
import { AppConsts } from '@shared/AppConsts';
import { AppUrlService } from '@shared/common/nav/app-url.service';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { AbpSessionService } from '@abp/session/abp-session.service';
import { AppUiCustomizationService } from '@shared/common/ui/app-ui-customization.service';
import { PrimengTableHelper } from '@shared/helpers/PrimengTableHelper';
import * as _ from 'lodash';
import { AvaCartItemDto, AvaPricingInfoDto, AvaProductDto, AvaPurchaseDecisionDtoOfAvaProductDto } from '../service-proxies/service-proxies';
import { ImpersonatorPermissionsService } from '../../app/shared/common/services/impersonator-permissions.service';
import * as moment from 'moment';
import { AppMarkets, ProductItemType } from '@shared/AppEnums';
import { AvaLocalizationService } from '@shared/services/ava-localization.service';
var AppComponentBase = /** @class */ (function () {
    function AppComponentBase(injector) {
        this.defaultDateFormat = 'YYYY-MM-DD';
        this.defaultDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
        this.isLoggedIn = abp.session.userId != null && abp.session.userId > 0;
        // Tenant
        this.isPartner = this.isLoggedIn && abp.session.tenantId > 0;
        this.isHost = this.isLoggedIn && !this.isPartner;
        this.impersonatorPermissionService = injector.get(ImpersonatorPermissionsService);
        this.localization = injector.get(LocalizationService);
        this.avalocalization = injector.get(AvaLocalizationService);
        this.permission = injector.get(PermissionCheckerService);
        this.feature = injector.get(FeatureCheckerService);
        this.notify = injector.get(NotifyService);
        this.setting = injector.get(SettingService);
        this.message = injector.get(MessageService);
        this.multiTenancy = injector.get(AbpMultiTenancyService);
        this.appSession = injector.get(AppSessionService);
        this.abpSession = injector.get(AbpSessionService);
        this.ui = injector.get(AppUiCustomizationService);
        this.appUrlService = injector.get(AppUrlService);
        this.primengTableHelper = new PrimengTableHelper();
    }
    AppComponentBase.prototype.l = function (key) {
        var args = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            args[_i - 1] = arguments[_i];
        }
        return (_a = this.avalocalization).l.apply(_a, [key].concat(args));
        var _a;
    };
    AppComponentBase.prototype.isGranted = function (permissionName, impersonationPermission) {
        var userPermission = this.permission.isGranted(permissionName);
        if (!userPermission || !impersonationPermission)
            return userPermission;
        return this.isGrantedForImpersonator(impersonationPermission);
    };
    AppComponentBase.prototype.isGrantedForImpersonator = function (permissionName) {
        if (this.abpSession.impersonatorUserId == 0 || this.abpSession.impersonatorUserId == null)
            return true;
        return this.impersonatorPermissionService.isGranted(permissionName);
    };
    AppComponentBase.prototype.isGrantedAny = function () {
        var permissions = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            permissions[_i] = arguments[_i];
        }
        if (!permissions) {
            return false;
        }
        for (var _a = 0, permissions_1 = permissions; _a < permissions_1.length; _a++) {
            var permission = permissions_1[_a];
            if (this.isGranted(permission)) {
                return true;
            }
        }
        return false;
    };
    AppComponentBase.prototype.s = function (key) {
        return abp.setting.get(key);
    };
    AppComponentBase.prototype.appRootUrl = function () {
        return this.appUrlService.appRootUrl;
    };
    AppComponentBase.prototype.paginate = function (event, data) {
        var pg = event.page || 0;
        var pgSize = event.rows || this.primengTableHelper.defaultRecordsCountPerPage;
        var offset = pg * pgSize;
        this.primengTableHelper.records = _(data)
            .slice(offset < 0 ? 0 : offset)
            .take(pgSize)
            .value();
    };
    AppComponentBase.prototype.customPaginate = function (event, data) {
        var pg = event.page || 0;
        var pgSize = event.rows || this.primengTableHelper.defaultRecordsCountPerPage;
        var offset = pg * pgSize;
        return _(data)
            .slice(offset < 0 ? 0 : offset)
            .take(pgSize)
            .value();
    };
    AppComponentBase.prototype.getHelpDeskEmail = function () {
        return AppConsts.helpDeskEmail;
    };
    AppComponentBase.prototype.getHelpDeskEmailAuthSupport = function () {
        return AppConsts.helpDeskEmailAuthSupport;
    };
    AppComponentBase.prototype.getSupportEmailAddress = function () {
        return AppConsts.supportEmailAddress;
    };
    AppComponentBase.prototype.getPartnerCenterUrl = function () {
        return AppConsts.partnerNetworkUrl;
    };
    AppComponentBase.prototype.isEmptyObject = function (obj) {
        for (var prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                return false;
            }
        }
        return true;
    };
    AppComponentBase.prototype.isSpecialChar = function (c) {
        return ((c > 64 && c < 91) || (c > 96 && c < 123) || c == 8 || c == 32 || (c >= 48 && c <= 57));
    };
    AppComponentBase.prototype.isNewline = function (code) {
        return ((code == 'Enter'));
    };
    AppComponentBase.prototype.getDealTypeEnumName = function (dealType) {
        switch (dealType) {
            case 1:
                return this.l("MarketplaceQuote");
            case 2:
                return this.l("CCWQuote");
            case 3:
                return this.l("CCWEstimate");
            case 4:
                return this.l("HPEIQouteBasket");
            case 5:
                return this.l("CCWRenewal");
            default:
                return this.l("MarketplaceQuote");
        }
    };
    AppComponentBase.prototype.getLeadTimeLabel = function (leadTime) {
        var leadTimeLabel = moment.duration(moment().diff(leadTime)).humanize();
        if (leadTimeLabel === 'a few seconds') {
            return 'N/A';
        }
        return leadTimeLabel;
    };
    AppComponentBase.prototype.mapDealToCartItem = function (deal, cartItemId, cartId) {
        var priceExcluding = deal.totalExc;
        var priceIncluding = deal.totalInc;
        var product = new AvaProductDto({
            description: deal.description,
            name: deal.name,
            market: deal.market.valueOf(),
            productIdentifier: deal.dealIdentifier,
            brand: deal.brand.valueOf(),
            itemType: ProductItemType.Deal.valueOf(),
            dealType: deal.dealType.valueOf(),
            imageUrl: undefined,
            additionalInfo: deal.additionalInfo,
            brandInfo: undefined,
            category: undefined,
            availableToSell: undefined,
            estimatedTimeOfArrival: undefined,
            discount: undefined,
            discountPercentage: undefined,
            price: priceIncluding,
            currencyCode: undefined,
            promotions: undefined,
            tags: undefined,
            isDeal: undefined,
            vendorId: undefined,
        });
        var purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: product,
            tenantId: deal.tenantId,
            customerId: deal.customerId,
            purchaseOptionId: undefined,
            purchaseOptionDescription: undefined,
            autoRenew: undefined,
            quantity: 1,
            addOns: undefined,
            productConfigurationId: undefined,
            termUnitOfMeasure: undefined,
            parentOfferId: undefined,
            term: undefined,
            offerId: undefined,
            billing: undefined,
            billingFrequencyType: undefined
        });
        var pricingInfo = new AvaPricingInfoDto({
            discount: undefined,
            discountPercentage: undefined,
            unitPriceExc: priceExcluding,
            unitPriceInc: priceIncluding,
            sellPriceExc: priceExcluding,
            sellPriceInc: priceIncluding,
            quantity: 1,
            inStock: undefined,
            currencyCode: deal.sellCurrency,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice: undefined,
        });
        var cartItem = new AvaCartItemDto({
            purchaseDecision: purchaseDecision,
            pricingSummary: pricingInfo,
            splitPricing: undefined,
            cartId: cartId,
            cartItemId: cartItemId,
            tags: undefined,
            isValid: undefined,
            exception: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });
        return cartItem;
    };
    AppComponentBase.prototype.mapAvaProductToCartItem = function (item) {
        var priceExcluding = Number(item.price);
        var priceIncluding = Number(item.additionalInfo['IncSellPrice']);
        var product = item;
        product.description = product.description ? product.description : product.name;
        product.itemType = ProductItemType.Product.valueOf();
        var purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: new AvaProductDto(product),
            tenantId: undefined,
            customerId: undefined,
            purchaseOptionId: undefined,
            purchaseOptionDescription: undefined,
            autoRenew: undefined,
            quantity: 1,
            addOns: undefined,
            productConfigurationId: undefined,
            termUnitOfMeasure: undefined,
            parentOfferId: undefined,
            term: undefined,
            offerId: undefined,
            billing: undefined,
            billingFrequencyType: undefined
        });
        var pricingInfo = new AvaPricingInfoDto({
            discount: undefined,
            discountPercentage: undefined,
            unitPriceExc: priceExcluding / purchaseDecision.quantity,
            unitPriceInc: priceIncluding / purchaseDecision.quantity,
            sellPriceExc: priceExcluding ? priceExcluding : 0,
            sellPriceInc: priceIncluding ? priceIncluding : 0,
            quantity: purchaseDecision.quantity,
            inStock: item.availableToSell > 0,
            currencyCode: item.currencyCode,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice: undefined,
        });
        var cartItem = new AvaCartItemDto({
            purchaseDecision: purchaseDecision,
            pricingSummary: pricingInfo,
            splitPricing: undefined,
            cartId: undefined,
            cartItemId: undefined,
            tags: undefined,
            isValid: undefined,
            exception: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });
        return cartItem;
    };
    AppComponentBase.prototype.mapAvaProductDetailToPurchaseDecision = function (productDetail, isAutoRenew, purchaseQuantity, purchaseOptionsDisabled, purchaseOptionId, purchaseOptionDescription, pricedProductPrice, addons, productConfiguration, customerId) {
        var productConfigurationId = productConfiguration ? productConfiguration.configurationIdentifier : undefined;
        var productName = productConfiguration ? productConfiguration.configurationName : productDetail.name;
        var product = new AvaProductDto({
            imageUrl: productDetail.imageUrl,
            brandInfo: productDetail.brandInfo,
            category: productDetail.category,
            availableToSell: productDetail.availableToSell,
            estimatedTimeOfArrival: productDetail.estimatedTimeOfArrival,
            discount: productDetail.discount,
            dealType: productDetail.dealType ? productDetail.dealType.valueOf() : undefined,
            description: productDetail.description,
            name: productName,
            price: pricedProductPrice,
            currencyCode: productDetail.currencyCode,
            productIdentifier: productDetail.productIdentifier,
            promotions: productDetail.promotions,
            tags: productDetail.tags,
            brand: productDetail.brand.valueOf(),
            market: productDetail.market.valueOf(),
            itemType: productDetail.itemType.valueOf(),
            additionalInfo: productDetail.additionalInfo,
        });
        var purchaseDecision = new AvaPurchaseDecisionDtoOfAvaProductDto({
            product: product,
            tenantId: undefined,
            customerId: customerId,
            purchaseOptionDescription: purchaseOptionsDisabled ? undefined : purchaseOptionDescription,
            purchaseOptionId: purchaseOptionsDisabled ? undefined : purchaseOptionId,
            autoRenew: isAutoRenew,
            quantity: purchaseQuantity,
            addOns: addons,
            productConfigurationId: productConfigurationId
        });
        return purchaseDecision;
    };
    AppComponentBase.prototype.mapAvaPurchaseDecisionToCartItem = function (purchaseDecision) {
        var priceInc = 0;
        var priceExc = 0;
        switch (purchaseDecision.product.market) {
            case AppMarkets.Microsoft:
            case AppMarkets.Adobe: {
                priceInc = AppConsts.addVAT(AppConsts.VATMultipler, purchaseDecision.product.price);
                priceExc = purchaseDecision.product.price;
                break;
            }
            default: {
                priceInc = Number.parseFloat(purchaseDecision.product.additionalInfo['IncSellPrice']);
                priceExc = purchaseDecision.product.price;
                break;
            }
        }
        var pricingSummary = new AvaPricingInfoDto({
            unitPriceExc: priceExc,
            unitPriceInc: priceInc,
            quantity: purchaseDecision.quantity,
            discount: purchaseDecision.product.discount ? purchaseDecision.product.discount : 0,
            discountPercentage: purchaseDecision.product.discountPercentage ? purchaseDecision.product.discountPercentage : 0,
            sellPriceExc: priceExc * purchaseDecision.quantity,
            sellPriceInc: priceInc * purchaseDecision.quantity,
            inStock: purchaseDecision.product.availableToSell > 0,
            currencyCode: purchaseDecision.product.currencyCode,
            tags: undefined,
            exception: undefined,
            additionalInfo: undefined,
            splitIndicator: undefined,
            duties: undefined,
            roeUsed: undefined,
            freightFinanceMarginUplift: undefined,
            resellerOriginalSellPrice: undefined,
            availableToSell: undefined,
            unitListPrice: undefined,
            upliftSum: undefined,
            extendedListPrice: undefined,
            margin: undefined,
            roEConvertedListPrice: undefined,
        });
        var cartItem = new AvaCartItemDto({
            purchaseDecision: new AvaPurchaseDecisionDtoOfAvaProductDto(purchaseDecision),
            cartId: undefined,
            cartItemId: undefined,
            isValid: undefined,
            tags: undefined,
            exception: undefined,
            pricingSummary: pricingSummary,
            splitPricing: undefined,
            additionalInfo: undefined,
            totalExc: undefined,
            totalInc: undefined
        });
        return cartItem;
    };
    return AppComponentBase;
}());
export { AppComponentBase };
