import { PermissionCheckerService } from '@abp/auth/permission-checker.service';
import { AppMenu } from './app-menu';
import { AppMenuItem, MenuItemImageLink, MenuItemUsers } from './app-menu-item';
import { AppMarkets } from '@shared/AppEnums';
import { AppConsts } from "@shared/AppConsts";
var AppNavigationService = /** @class */ (function () {
    function AppNavigationService(_permissionService) {
        this._permissionService = _permissionService;
        this.assetsImagesFolder = './assets/common/images';
        // msColorImage = `${this.assetsImagesFolder}/microsoft-color.png`;
        this.msColorImage = this.assetsImagesFolder + "/Microsoft_logo.png";
        this.msMarketColorImage = this.assetsImagesFolder + "/microsoft-market-color.png";
        this.ciscoColorImage = this.assetsImagesFolder + "/Cisco-color.png";
        this.ibmColorImage = this.assetsImagesFolder + "/IBM_Color_prev.png";
    }
    AppNavigationService.prototype.getMenu = function () {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem({
                name: 'MarketPlace', permissionName: 'Pages.Markets.GeneralMarket.Store', route: '/app/main/markets/general', impersonatorPermission: 'Pages.Tenants.Impersonation.Markets.GeneralMarket.PlaceOders', users: [MenuItemUsers.Guest, MenuItemUsers.Tenant]
            }),
            new AppMenuItem({
                name: 'Cloud', permissionName: 'Pages.Markets', users: [MenuItemUsers.Guest, MenuItemUsers.Tenant, MenuItemUsers.CustomGuest], items: [
                    new AppMenuItem({
                        name: 'IBM', permissionName: 'Pages.Markets.IBM', route: '/app/main/markets/ibm', users: [MenuItemUsers.Tenant],
                        imageLink: new MenuItemImageLink(this.assetsImagesFolder + "/IBM_Grey_prev.png", this.ibmColorImage)
                    }),
                    new AppMenuItem({
                        name: 'Microsoft', permissionName: 'Pages.Markets.Microsoft.Store', route: '/app/main/store?market=2', users: [MenuItemUsers.Guest, MenuItemUsers.Tenant],
                        imageLink: new MenuItemImageLink(this.assetsImagesFolder + "/microsoft-grey.png", this.msColorImage)
                    }),
                    new AppMenuItem({
                        name: 'Redhat', permissionName: 'Pages.Markets.Redhat.CCSPReport', route: '/app/main/markets/redhat/reporting/ccsp-report', users: [MenuItemUsers.Tenant],
                        imageLink: new MenuItemImageLink(this.assetsImagesFolder + "/redhat-grey.png", this.assetsImagesFolder + "/redhat-color.png")
                    })
                ]
            }),
            new AppMenuItem({
                name: "Deals", permissionName: 'Pages.Markets.Deals', impersonatorPermission: '', route: '/app/deals/list', users: [MenuItemUsers.Tenant, MenuItemUsers.Host]
            }),
            new AppMenuItem({
                name: 'Media', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: 'https://info.axiz.com/news/'
            }),
            new AppMenuItem({
                name: 'Contact', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: 'https://info.axiz.com/contact-us/'
            }),
            new AppMenuItem({
                name: 'Partners', permissionName: 'Pages.Tenants', route: '/app/admin/tenants', users: [MenuItemUsers.Host]
            }),
            new AppMenuItem({
                name: 'NotificationCenter', permissionName: 'Pages.Administration.NotificationCenter', route: '/app/admin/notification-center', users: [MenuItemUsers.Host]
            }),
            new AppMenuItem({
                name: 'Editions', permissionName: 'Pages.Editions', route: '/app/admin/editions', users: [MenuItemUsers.Host]
            }),
            // new AppMenuItem({
            //     name: 'Customers', permissionName: 'Pages.Customers', route: '/app/admin/customers', users: [MenuItemUsers.Tenant], impersonatorPermission: 'Pages.Tenants.Impersonation.ManageCustomers'
            // }),
            new AppMenuItem({
                name: 'Orders', permissionName: 'Pages.Orders', route: '/app/order/order-list', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], impersonatorPermission: 'Pages.Tenants.Impersonation.ViewOrders'
            }),
            // Commented out, but will leave it here in the case it should be activated again
            //new AppMenuItem({
            //    name: 'Parcel Tracking', permissionName: 'Pages.ParcelTracking', route: '/app/main/parcel-tracking', users: [MenuItemUsers.Tenant], impersonatorPermission: ''
            //}),
            new AppMenuItem({
                name: 'Administration', permissionName: 'Pages.Administration', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], impersonatorPermission: 'Pages.Tenants.Impersonation.Administration', items: [
                    new AppMenuItem({ name: 'Documents', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Documents', route: '/app/admin/documents' }),
                    new AppMenuItem({ name: 'Integration', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Integration', route: '/app/admin/integration' }),
                    new AppMenuItem({ name: 'Roles', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Roles', route: '/app/admin/roles' }),
                    new AppMenuItem({ name: 'Users', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Users', route: '/app/admin/users' }),
                    new AppMenuItem({ name: 'Settings', users: [MenuItemUsers.Host], permissionName: 'Pages.Administration.Host.Settings', route: '/app/admin/hostSettings' }),
                    new AppMenuItem({ name: 'OrganizationUnits', users: [MenuItemUsers.Host], permissionName: 'Pages.Administration.OrganizationUnits', route: '/app/admin/organization-units' }),
                    new AppMenuItem({ name: 'Settings', users: [MenuItemUsers.Tenant], permissionName: 'Pages.Administration.Tenant.Settings', route: '/app/admin/tenantSettings' }),
                    new AppMenuItem({ name: 'Journey', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.JourneyManager', route: '/app/admin/journey-management' }),
                    new AppMenuItem({
                        name: 'Pricelists', permissionName: 'Pages.Administration.PriceList', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], items: [
                            new AppMenuItem({ name: 'Upload', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.PriceList.Upload', route: '/app/admin/price-list/upload' }),
                            new AppMenuItem({ name: 'Microsoft', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.PriceList.Microsoft', route: '/app/admin/price-list/microsoft' }),
                        ]
                    }),
                    new AppMenuItem({
                        name: 'Vendors', permissionName: 'Pages.Administration.Vendors', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], items: [
                            new AppMenuItem({ name: 'Cisco', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Vendors.Cisco', route: '/app/admin/vendor/cisco' }),
                            new AppMenuItem({ name: 'Microsoft', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], permissionName: 'Pages.Administration.Vendors.Microsoft', route: '/app/admin/vendor/microsoft' }),
                            new AppMenuItem({ name: 'Marketplace', users: [MenuItemUsers.Host], permissionName: 'Pages.Administration.Vendors.MarketPlace', route: '/app/admin/vendor/market-place' }),
                        ]
                    }),
                    new AppMenuItem({ name: 'PricingMaintenance', users: [MenuItemUsers.Host], permissionName: 'Pages.Administration.Host.PricingMaintenance', route: '/app/admin/pricing-maintenance' }),
                ]
            }),
        ]);
    };
    AppNavigationService.prototype.getTopMenu = function () {
        return new AppMenu('MainMenu', 'MainMenu', [
            new AppMenuItem({
                name: 'More About Us', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: 'https://info.axiz.com/about/'
            }),
            new AppMenuItem({
                name: 'What We Do', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: AppConsts.whatWeDoLink
            }),
            new AppMenuItem({
                name: 'Our Vendor Partners', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: 'https://info.axiz.com/our-vendors/'
            }),
            new AppMenuItem({
                name: 'Get In Touch', users: [MenuItemUsers.Guest, MenuItemUsers.CustomGuest], actionLink: 'https://info.axiz.com/contact/'
            })
        ]);
    };
    AppNavigationService.prototype.getMegaMenu = function () {
        return new AppMenu('MainMenu', 'MainMenu', [
            /* Hide dashboard until it is built */
            /*  new AppMenuItem({
                 name: 'My Dashboard', permissionName:'Pages.Markets.Microsoft.Dashboard', route: '/app/main/dashboard/microsoft', users: [MenuItemUsers.Tenant], icon: 'fal fa-tachometer'
             }), */
            new AppMenuItem({
                name: 'Partner Center', route: '/app/main/partner-center', users: [MenuItemUsers.Tenant], icon: 'fa-light fa-gauge-high', marketingImage: this.assetsImagesFolder + "/marketingImages/image17.png"
            }),
            new AppMenuItem({
                name: 'Digital Commerce', permissionName: 'Pages.Markets.GeneralMarket.Store', route: AppConsts.DigitalCommercePath, paramName: 'page', paramValue: AppConsts.LandingPageGeneralDefaultConfig, impersonatorPermission: 'Pages.Tenants.Impersonation.Markets.GeneralMarket.PlaceOders', users: [MenuItemUsers.Guest, MenuItemUsers.Tenant, MenuItemUsers.Host], icon: 'fa-light fa-cart-shopping', marketingImage: this.assetsImagesFolder + "/marketingImages/image17.png"
            }),
            new AppMenuItem({
                name: 'Cloud Dashboard', permissionName: 'Pages.Markets', users: [MenuItemUsers.Guest, MenuItemUsers.Tenant, MenuItemUsers.CustomGuest, MenuItemUsers.Host], icon: 'fal fa-cloud', items: [
                    new AppMenuItem({
                        name: 'Adobe', permissionName: 'Pages.Markets.Adobe.Store', route: '/app/main/store', paramName: 'market', paramValue: AppMarkets.Adobe.toString(), users: [MenuItemUsers.Guest, MenuItemUsers.Tenant, MenuItemUsers.Host],
                        imageLink: new MenuItemImageLink(this.assetsImagesFolder + "/microsoft-grey.png", this.msColorImage), subSection: 'Categories'
                    }),
                    new AppMenuItem({
                        name: 'Microsoft', permissionName: 'Pages.Markets.Microsoft.Store', route: '/app/main/store', paramName: 'market', paramValue: AppMarkets.Microsoft.toString(), users: [MenuItemUsers.Guest, MenuItemUsers.Tenant, MenuItemUsers.Host],
                        imageLink: new MenuItemImageLink(this.assetsImagesFolder + "/microsoft-grey.png", this.msColorImage), subSection: 'Categories'
                    }),
                    /*  new AppMenuItem({  Disabled some work need to be done
                         name: 'Subscriptions', permissionName: 'Pages.Markets.Microsoft.Store', route: '/app/admin/manage-customer/20', users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage, subSection:'Tools' //TODO Remove hard coded customerId. Refactoring required
                     }), */
                    //todo remove, once menu structure is revamped, to show the landing page first, This will allow things to be tested without needing to manual change url
                    // new AppMenuItem({
                    //     name: 'Landing Page', permissionName: 'Pages.Markets.Microsoft.Store', route: AppConsts.LandingPagePath, paramName: 'page', paramValue:AppConsts.LandingPageCloudDefaultConfig, users: [MenuItemUsers.Guest, MenuItemUsers.Tenant],
                    //     imageLink: new MenuItemImageLink(`${this.assetsImagesFolder}/microsoft-grey.png`, this.msColorImage), subSection:'Categories'
                    // }),
                    new AppMenuItem({
                        name: 'Azure Consumption', permissionName: 'Pages.Markets.Microsoft.Dashboard', route: '/app/main/dashboard/microsoft', users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage, subSection: 'Tools'
                    }),
                    new AppMenuItem({
                        name: 'Billing', permissionName: 'Pages.Administration.Vendors.Microsoft', route: '/app/admin/vendor/microsoft', users: [MenuItemUsers.Host], icon: this.msMarketColorImage, subSection: 'Tools' //TODO Refactoring requierd to make this work on mega menu.
                    }),
                    new AppMenuItem({
                        name: 'Dashboard', permissionName: 'Pages.Markets.Microsoft.Dashboard', route: '/app/main/dashboard/microsoft', users: [MenuItemUsers.Tenant], subSection: 'Tools'
                    }),
                    new AppMenuItem({
                        name: 'Invoices', permissionName: 'Pages.Markets.Microsoft.Invoices', route: '/app/main/markets/microsoft-market/microsoft-invoices', users: [MenuItemUsers.Tenant], subSection: 'Tools'
                    }),
                    new AppMenuItem({
                        name: 'Noticeboard', permissionName: 'Pages.Markets.Microsoft', route: '/app/main/noticeboard/' + AppMarkets.Microsoft, users: [MenuItemUsers.Tenant], subSection: 'Tools'
                    }),
                    new AppMenuItem({
                        name: 'Subscription management', orPermissionNames: ['Pages.Markets.Microsoft', 'Pages.Markets.Adobe'], route: '/app/admin/manage-customer/subscription-management', actionLink: 'subscription-management', users: [MenuItemUsers.Tenant], subSection: 'Tools'
                    }),
                ]
            }),
            new AppMenuItem({
                name: 'Partners Manager', permissionName: 'Pages.Tenants', route: '/app/admin/tenants', users: [MenuItemUsers.Host], icon: 'fa-light fa-user-tie'
            }),
            //logged in Enterprise user
            new AppMenuItem({
                name: "Enterprise Deals", permissionName: 'Pages.Markets.Deals', impersonatorPermission: '', route: '/app/deals', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], icon: 'fal fa-handshake'
            }),
            //guest Enterprise user
            new AppMenuItem({
                name: "Enterprise Deals", permissionName: 'Pages.Markets.Deals', impersonatorPermission: '', route: AppConsts.EnterpriseCommercePath, paramName: 'page', paramValue: AppConsts.LandingPageEnterpriseDefaultConfig, users: [MenuItemUsers.Guest], icon: 'fal fa-handshake'
            }),
            new AppMenuItem({
                name: "Contract Management", permissionName: 'Pages.Markets.Cisco.Renewals', impersonatorPermission: '', route: '/app/deals/list-renewals', users: [MenuItemUsers.Tenant, MenuItemUsers.Host], icon: 'fa-thin fa-clock-rotate-left'
            }),
            new AppMenuItem({
                name: 'Order History', permissionName: 'Pages.Orders', route: '/app/order/order-list', users: [MenuItemUsers.Host], icon: 'fal fa-box-open', impersonatorPermission: 'Pages.Tenants.Impersonation.ViewOrders'
            }),
            /* new AppMenuItem({ Disabled. Work to be done
                name: 'Create a Store', permissionName: 'Pages.Administration.Host.Settings', route: '/app/admin/hostSettings', users: [MenuItemUsers.Host], icon: 'fal fa-store'
            }), */
            new AppMenuItem({
                name: 'Contract Management', permissionName: 'Pages.Administration.ContractManagement', route: '#', users: [MenuItemUsers.Tenant], icon: 'fal fa-box-open'
            }),
        ]);
    };
    AppNavigationService.prototype.getMarketNavLinks = function () {
        return [
            {
                market: AppMarkets.Microsoft, navLinks: [
                    new AppMenuItem({ name: 'Dashboard', permissionName: 'Pages.Markets.Microsoft.Dashboard', route: '/app/main/dashboard/microsoft', users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage }),
                    new AppMenuItem({ name: 'Store', permissionName: 'Pages.Markets.Microsoft.Store', route: '/app/main/store?market=2', users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage }),
                    new AppMenuItem({ name: 'Noticeboard', permissionName: 'Pages.Markets.Microsoft', route: '/app/main/noticeboard/' + AppMarkets.Microsoft, users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage }),
                    new AppMenuItem({ name: 'Invoices', permissionName: 'Pages.Markets.Microsoft.Invoices', route: '/app/main/markets/microsoft-market/microsoft-invoices', users: [MenuItemUsers.Tenant], icon: this.msMarketColorImage }),
                ]
            },
            {
                market: AppMarkets.Cisco, navLinks: [
                    new AppMenuItem({ name: 'Dashboard', permissionName: 'Pages.Markets.Cisco.Dashboard', route: '/app/journeys/cisco', users: [MenuItemUsers.Tenant], icon: this.ciscoColorImage }),
                    new AppMenuItem({ name: 'Store', permissionName: 'Pages.Markets.Cisco.Store', route: '/app/main/markets/cisco', users: [MenuItemUsers.Tenant], icon: this.ciscoColorImage }),
                    new AppMenuItem({ name: 'Estimates', permissionName: 'Pages.Markets.Cisco.Estimate', route: '/app/estimate/cisco', users: [MenuItemUsers.Tenant], icon: this.ciscoColorImage }),
                    new AppMenuItem({ name: 'Noticeboard', permissionName: 'Pages.Markets.Cisco', route: '/app/main/noticeboard/' + AppMarkets.Cisco, users: [MenuItemUsers.Tenant], icon: this.ciscoColorImage }),
                ]
            },
            {
                market: AppMarkets.IBM, navLinks: [
                    new AppMenuItem({ name: 'Store', route: '/app/main/markets/ibm', users: [MenuItemUsers.Tenant], icon: this.ibmColorImage }),
                    new AppMenuItem({ name: 'Noticeboard', permissionName: 'Pages.Markets.IBM', route: '/app/main/noticeboard/' + AppMarkets.GeneralMarket, users: [MenuItemUsers.Tenant], icon: this.ibmColorImage }),
                ]
            },
            {
                market: AppMarkets.GeneralMarket, navLinks: [
                    new AppMenuItem({ name: 'Store', permissionName: 'Pages.Markets.GeneralMarket.Store', route: '/app/main/store', users: [MenuItemUsers.Tenant] }),
                    new AppMenuItem({ name: 'Noticeboard', permissionName: 'Pages.Markets.GeneralMarket', route: '/app/journeys/cisco', users: [MenuItemUsers.Tenant] }),
                ]
            }
        ];
    };
    AppNavigationService.prototype.getNavLinksByMarket = function (market) {
        var links = this.getMarketNavLinks().filter(function (l) { return l.market == market; });
        if (links && links[0].navLinks)
            return links[0].navLinks;
        return [];
    };
    AppNavigationService.prototype.checkChildMenuItemPermission = function (menuItem) {
        for (var i = 0; i < menuItem.items.length; i++) {
            var subMenuItem = menuItem.items[i];
            if (subMenuItem.permissionName && this._permissionService.isGranted(subMenuItem.permissionName)) {
                return true;
            }
            if (subMenuItem.items && subMenuItem.items.length) {
                return this.checkChildMenuItemPermission(subMenuItem);
            }
            else if (!subMenuItem.permissionName) {
                return true;
            }
        }
        return false;
    };
    return AppNavigationService;
}());
export { AppNavigationService };
