import { OrderCustomerContactDto, RateOfExchangeServiceProxy } from './../../../shared/service-proxies/service-proxies';
import { EventEmitter } from '@angular/core';
import { ShoppingCartWorkflowService } from '@app/cart/services/shopping-cart-workflow.service';
import { AppCartSteps } from '@app/cart/models/shopping-cart-steps';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { ShoppingCartFormLocalStorageService } from '@app/cart/services/shopping-cart-form-local-storage.service';
import { cloneShoppingCartFormDataDto } from '@shared/service-proxies/service-proxies-extension';
import { BehaviorSubject } from 'rxjs';
import * as moment from "@node_modules/moment";
import * as i0 from "@angular/core";
import * as i1 from "./shopping-cart-workflow.service";
import * as i2 from "../../../shared/common/session/app-session.service";
import * as i3 from "./shopping-cart-form-local-storage.service";
import * as i4 from "../../../shared/service-proxies/service-proxies";
var ShoppingCartFormService = /** @class */ (function () {
    function ShoppingCartFormService(shoppingCartWorkflowService, appSessionService, shoppingCartFormLocalStorageService, roeService) {
        this.shoppingCartWorkflowService = shoppingCartWorkflowService;
        this.appSessionService = appSessionService;
        this.shoppingCartFormLocalStorageService = shoppingCartFormLocalStorageService;
        this.roeService = roeService;
        this.DisableCheckout = new BehaviorSubject(undefined);
        this.ClearDisableCheckout = new EventEmitter();
        this.MustCompletePayment = new BehaviorSubject(false);
        this.DisabledByExceedMax = new BehaviorSubject(undefined);
        this.RecalculatingPricing = new BehaviorSubject(false);
        this.CompletePayment = new BehaviorSubject(false);
        this.NextButtonText = new BehaviorSubject('');
        this.PaymentMethodChanged = new BehaviorSubject(false);
        this.isCartItemsValid = false;
        this.isCheckoutFormValid = false;
        this.isPaymentFormValid = false;
        this.isFinishFormValid = false;
        this.roeZARtoCartCurrency = 1;
    }
    Object.defineProperty(ShoppingCartFormService.prototype, "MustCompletePayment$", {
        get: function () {
            return this.MustCompletePayment.asObservable(); // returns BehaviourSubject as an Obervable
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "DisableCheckout$", {
        get: function () {
            return this.DisableCheckout;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "RecalculatingPricing$", {
        get: function () {
            return this.RecalculatingPricing.asObservable(); // returns BehaviourSubject as an Obervable
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "CompletePayment$", {
        get: function () {
            return this.CompletePayment.asObservable(); // returns BehaviourSubject as an Obervable
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "NextButtonText$", {
        get: function () {
            return this.NextButtonText.asObservable(); // returns BehaviourSubject as an Obervable
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "PaymentMethodChanged$", {
        get: function () {
            return this.PaymentMethodChanged.asObservable(); // returns BehaviourSubject as an Obervable
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ShoppingCartFormService.prototype, "formData", {
        get: function () {
            if (this._formData && this._formData !== null) {
                return this._formData;
            }
            return (this._formData = cloneShoppingCartFormDataDto(this.shoppingCartFormLocalStorageService.getFormData()));
        },
        enumerable: true,
        configurable: true
    });
    /**
     * @param disable true if checkout should be disable for reason. false, if the checkout should not be disable for reason.
     * @param reason for the checkout disable state
     */
    ShoppingCartFormService.prototype.UpdateDisableCheckout = function (disable, reason) {
        return this.DisableCheckout.next({
            disable: disable,
            reason: reason
        });
    };
    /**
     * Initialize data at disposal using the tenant service
     */
    ShoppingCartFormService.prototype.checkAndInitCheckoutData = function (checkoutData) {
        var userData = this.appSessionService.user;
        var tenantData = this.appSessionService.tenant;
        checkoutData.userLoginInfo = userData;
        checkoutData.tenantLoginInfo = tenantData;
        checkoutData.customerContact = new OrderCustomerContactDto();
        checkoutData.customerContact.type = 'END_CUSTOMER';
        return checkoutData;
    };
    /**
     *  Return the Checkout data
     */
    ShoppingCartFormService.prototype.getCheckoutData = function () {
        return this.checkAndInitCheckoutData(this.formData.checkoutData);
    };
    ShoppingCartFormService.prototype.setPaymentTerm = function (term, monthlyPayment, subTotal, termQty) {
        this.formData.checkoutData.paymentTerm = term;
        this.formData.checkoutData.monthlyPayment = monthlyPayment;
        this.formData.checkoutData.newSubTotal = subTotal;
        this.formData.checkoutData.paymentTermQty = termQty;
    };
    ShoppingCartFormService.prototype.setPaymentMethod = function (paymentMethod) {
        this.formData.checkoutData.paymentMethod = paymentMethod;
    };
    // we use tenant reference for all payment options, because SAGE only accepts data on the resellerReference field.
    ShoppingCartFormService.prototype.setTenantReference = function (reference) {
        this.formData.checkoutData.tenantReference = reference;
    };
    ShoppingCartFormService.prototype.hasTenantReference = function () {
        return this.formData.checkoutData.tenantReference != null || this.formData.checkoutData.tenantReference != undefined || this.formData.checkoutData.tenantReference != "";
    };
    ShoppingCartFormService.prototype.setPODocFlag = function (hasDoc) {
        this.formData.checkoutData.hasPODocument = hasDoc;
    };
    /**
     * Update the Checkout data only when the Checkout Form had been validated successfully
     * @param checkoutData
     */
    ShoppingCartFormService.prototype.setCheckoutData = function (checkoutData, invalidateStep) {
        this.formData.checkoutData = checkoutData;
        //this.shoppingCartFormLocalStorageService.saveFormData(this.formData);
        if (invalidateStep === true) {
            this.shoppingCartWorkflowService.invalidateStep(AppCartSteps.checkout);
            this.shoppingCartWorkflowService.invalidateStep(AppCartSteps.finish);
        }
        else {
            this.shoppingCartWorkflowService.validateStep(AppCartSteps.checkout);
        }
    };
    /**
     * Return the entire Form Data
     */
    ShoppingCartFormService.prototype.getFormData = function () {
        this._formData = this.formData;
        return this.formData;
    };
    /**
     * Return the form data after all this.* members had been reset
     */
    ShoppingCartFormService.prototype.resetFormData = function () {
        //this.shoppingCartFormLocalStorageService.resetFormData();
        this._formData = null;
        this.isCartItemsValid = this.isCheckoutFormValid = this.isPaymentFormValid = this.isFinishFormValid = false;
        this.shoppingCartWorkflowService.resetSteps();
        return this.formData;
    };
    /**
     * Return true if all forms had been validated successfully; otherwise, return false
     */
    ShoppingCartFormService.prototype.isFormValid = function () {
        return this.isCartItemsValid && this.isCheckoutFormValid && this.isPaymentFormValid && this.isFinishFormValid;
    };
    ShoppingCartFormService.prototype.setPaymentReference = function (paymentReference) {
        if (paymentReference) {
            this._formData.checkoutData.internalReference = paymentReference.avaReferenceNumber;
            this._paymentReference = paymentReference;
        }
        else if (this._paymentReference) {
            this._formData.checkoutData.internalReference = this._paymentReference.avaReferenceNumber;
        }
    };
    ShoppingCartFormService.prototype.setResellerAccountNumber = function (resellerAccountNumber) {
        this._formData.checkoutData.resellerAccountNumber = resellerAccountNumber;
    };
    Object.defineProperty(ShoppingCartFormService.prototype, "paymentReference", {
        get: function () {
            return this._paymentReference;
        },
        enumerable: true,
        configurable: true
    });
    ShoppingCartFormService.prototype.setZARExchangeRate = function (sellCurrency, market) {
        var _this = this;
        this.roeService.getRateOfExchange('ZAR', sellCurrency, moment(), market)
            .subscribe(function (rate) {
            _this.roeZARtoCartCurrency = rate;
        });
    };
    ShoppingCartFormService.ngInjectableDef = i0.defineInjectable({ factory: function ShoppingCartFormService_Factory() { return new ShoppingCartFormService(i0.inject(i1.ShoppingCartWorkflowService), i0.inject(i2.AppSessionService), i0.inject(i3.ShoppingCartFormLocalStorageService), i0.inject(i4.RateOfExchangeServiceProxy)); }, token: ShoppingCartFormService, providedIn: "root" });
    return ShoppingCartFormService;
}());
export { ShoppingCartFormService };
