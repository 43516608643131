import {
    OrderCustomerContactDto,
    PaymentReferenceDto,
    RateOfExchangeServiceProxy
} from './../../../shared/service-proxies/service-proxies';
import {EventEmitter, Injectable} from '@angular/core';
import {ShoppingCartWorkflowService} from '@app/cart/services/shopping-cart-workflow.service';
import {AppCartSteps} from '@app/cart/models/shopping-cart-steps';
import {AppSessionService} from '@shared/common/session/app-session.service';
import {ShoppingCartFormLocalStorageService} from '@app/cart/services/shopping-cart-form-local-storage.service';
import {CheckoutDataDto, ShoppingCartFormDataDto} from '@shared/service-proxies/service-proxies';
import {cloneShoppingCartFormDataDto} from '@shared/service-proxies/service-proxies-extension';
import {CartCheckoutDisable} from '@shared/AppEnums';
import {BehaviorSubject} from 'rxjs';
import * as moment from "@node_modules/moment";

export interface CheckoutDisable {
    disable: boolean,
    reason: CartCheckoutDisable
}

@Injectable({
    providedIn: 'root',
})
export class ShoppingCartFormService {
    private _formData: ShoppingCartFormDataDto;
    private _paymentReference: PaymentReferenceDto;
    private DisableCheckout = new BehaviorSubject<CheckoutDisable>(undefined);
    ClearDisableCheckout: EventEmitter<{}> = new EventEmitter();
    
    public MustCompletePayment = new BehaviorSubject<boolean>(false);

    public get MustCompletePayment$() {
        return this.MustCompletePayment.asObservable(); // returns BehaviourSubject as an Obervable
    }

    public DisabledByExceedMax = new BehaviorSubject<number>(undefined);

    public get DisableCheckout$() {
        return this.DisableCheckout;
    }

    public RecalculatingPricing = new BehaviorSubject<boolean>(false);

    public get RecalculatingPricing$() {
        return this.RecalculatingPricing.asObservable(); // returns BehaviourSubject as an Obervable
    }

    public CompletePayment = new BehaviorSubject<boolean>(false);

    public get CompletePayment$() {
        return this.CompletePayment.asObservable(); // returns BehaviourSubject as an Obervable
    }

    public NextButtonText = new BehaviorSubject<string>('');

    public get NextButtonText$() {
        return this.NextButtonText.asObservable(); // returns BehaviourSubject as an Obervable
    }

    public PaymentMethodChanged = new BehaviorSubject<boolean>(false);

    public get PaymentMethodChanged$() {
        return this.PaymentMethodChanged.asObservable(); // returns BehaviourSubject as an Obervable
    }

    private get formData(): ShoppingCartFormDataDto {
        if (this._formData && this._formData !== null) {
            return this._formData;
        }

        return (this._formData = cloneShoppingCartFormDataDto(this.shoppingCartFormLocalStorageService.getFormData()));
    }

    private isCartItemsValid = false;
    private isCheckoutFormValid = false;
    private isPaymentFormValid = false;
    private isFinishFormValid = false;
    public roeZARtoCartCurrency: number = 1;

    constructor(
        private shoppingCartWorkflowService: ShoppingCartWorkflowService,
        private appSessionService: AppSessionService,
        private shoppingCartFormLocalStorageService: ShoppingCartFormLocalStorageService,
        private roeService: RateOfExchangeServiceProxy
    ) {
    }
    
    /**
     * @param disable true if checkout should be disable for reason. false, if the checkout should not be disable for reason.
     * @param reason for the checkout disable state
     */
    public UpdateDisableCheckout(disable: boolean, reason: CartCheckoutDisable) {
        return this.DisableCheckout.next( {
            disable: disable,
            reason: reason
        })
    }

    /**
     * Initialize data at disposal using the tenant service
     */
    private checkAndInitCheckoutData(checkoutData: CheckoutDataDto): CheckoutDataDto {
        const userData = this.appSessionService.user;
        const tenantData = this.appSessionService.tenant;
        checkoutData.userLoginInfo = userData;
        checkoutData.tenantLoginInfo = tenantData;
        checkoutData.customerContact = new OrderCustomerContactDto();
        checkoutData.customerContact.type = 'END_CUSTOMER';
        return checkoutData;
    }

    /**
     *  Return the Checkout data
     */
    getCheckoutData(): CheckoutDataDto {
        return this.checkAndInitCheckoutData(this.formData.checkoutData);
    }

    setPaymentTerm(term: string, monthlyPayment: number, subTotal: number, termQty: string) {
        this.formData.checkoutData.paymentTerm = term;
        this.formData.checkoutData.monthlyPayment = monthlyPayment;
        this.formData.checkoutData.newSubTotal = subTotal;
        this.formData.checkoutData.paymentTermQty = termQty;
    }

    setPaymentMethod(paymentMethod: string) {
        this.formData.checkoutData.paymentMethod = paymentMethod;
    }

    // we use tenant reference for all payment options, because SAGE only accepts data on the resellerReference field.
    setTenantReference(reference: string) {
        this.formData.checkoutData.tenantReference = reference;
    }
    
    hasTenantReference() {
        return this.formData.checkoutData.tenantReference != null || this.formData.checkoutData.tenantReference != undefined || this.formData.checkoutData.tenantReference != "";
    }

    setPODocFlag(hasDoc: boolean) {
        this.formData.checkoutData.hasPODocument = hasDoc;
    }

    /**
     * Update the Checkout data only when the Checkout Form had been validated successfully
     * @param checkoutData
     */
    setCheckoutData(checkoutData: CheckoutDataDto, invalidateStep: boolean) {
        this.formData.checkoutData = checkoutData;
        //this.shoppingCartFormLocalStorageService.saveFormData(this.formData);

        if (invalidateStep === true) {
            this.shoppingCartWorkflowService.invalidateStep(AppCartSteps.checkout);
            this.shoppingCartWorkflowService.invalidateStep(AppCartSteps.finish);
        } else {
            this.shoppingCartWorkflowService.validateStep(AppCartSteps.checkout);
        }
    }

    /**
     * Return the entire Form Data
     */
    getFormData(): ShoppingCartFormDataDto {
        this._formData = this.formData;
        return this.formData;
    }

    /**
     * Return the form data after all this.* members had been reset
     */
    resetFormData(): ShoppingCartFormDataDto {
        //this.shoppingCartFormLocalStorageService.resetFormData();
        this._formData = null;
        this.isCartItemsValid = this.isCheckoutFormValid = this.isPaymentFormValid = this.isFinishFormValid = false;
        this.shoppingCartWorkflowService.resetSteps();
        return this.formData;
    }

    /**
     * Return true if all forms had been validated successfully; otherwise, return false
     */
    isFormValid() {
        return this.isCartItemsValid && this.isCheckoutFormValid && this.isPaymentFormValid && this.isFinishFormValid;
    }

    setPaymentReference(paymentReference?: PaymentReferenceDto) {
        if (paymentReference) {
            this._formData.checkoutData.internalReference = paymentReference.avaReferenceNumber;
            this._paymentReference = paymentReference;
        } else if (this._paymentReference) {
            this._formData.checkoutData.internalReference = this._paymentReference.avaReferenceNumber;
        }
    }

    setResellerAccountNumber(resellerAccountNumber: string) {
        this._formData.checkoutData.resellerAccountNumber = resellerAccountNumber;
    }

    public get paymentReference(): PaymentReferenceDto {
        return this._paymentReference;
    }

    setZARExchangeRate(sellCurrency: string, market: number) {
        this.roeService.getRateOfExchange('ZAR', sellCurrency, moment(), market)
            .subscribe(rate => {
                this.roeZARtoCartCurrency = rate;
            })
    }
}
